let KALEIDOSCOOP = KALEIDOSCOOP || {};

KALEIDOSCOOP.Main = function () {
};


$(function () {
    jQueryUI_datepicker();

    let $body = $('body');

/*    $(document).off('keydown.bs.dropdown.data-api');
    $('select').off('keydown.bs.dropdown.data-api');
    $(document).off('click.bs.dropdown.data-api');
    $('select').off('click.bs.dropdown.data-api');*/
    $('select[data-trigger="multi-select"]').selectpicker();

    if (window.location.hash) {
        let $target = $('body').find(window.location.hash);

        if($target.hasClass('collapse')) {
            $target.collapse('show');

            let $anchor = $(window.location.hash).offset();
            if($anchor !== undefined) {
                window.scrollTo(0,$anchor.top - 175); //175 = navbar + card-header
            }
        }
    }

    //FullCalendar
    let $calendarEl = $('div#fullcalendar');

    if($calendarEl.length) {
        var calendar = new FullCalendar.Calendar($calendarEl[0], {
            locale: 'nl',
            timeZone: 'local',
            initialView: 'timeGridWeek',
            themeSystem: 'bootstrap',
            height: 'auto',
            allDaySlot: false,
            slotEventOverlap: false,
            scrollTime: '08:00:00',
            slotMinTime: '07:00:00',
            slotMaxTime: '21:00:00',
            headerToolbar: {
                left: 'prev,next today',
                center: 'title',
                right: 'timeGridWeek,listWeek'
            },
            events: $calendarEl.data('feed'),
            // eventBackgroundColor: '#ee0000',
            // eventBorderColor: '#3788d8',
            businessHours: {
                // days of week. an array of zero-based day of week integers (0=Sunday)
                daysOfWeek: [1, 2, 3, 4, 5], // Monday - Friday
                startTime: '08:00:00', // a start time (8am in this example)
                endTime: '18:00', // an end time (6pm in this example)
            },
            eventClick: function (info) {
                var myModal = new bootstrap.Modal(document.getElementById('fullcalendarModal'))

                $('#fullcalendarModal').on('show.bs.modal', function () {
                    $('.modal-title').text(info.event.title);

                    $('.modal-body span.date').text( moment(info.event.start).format('LL') );
                    $('.modal-body span.time').text( moment(info.event.start).format('LT') + ' - ' + moment(info.event.end).format('LT'));
                    $('.modal-body span.teacher').text( info.event.extendedProps.teacher );

                    $('.modal-body div.group').hide();
                    if(info.event.extendedProps.schoolgroup > 0) {
                        $('.modal-body span.group').text( info.event.extendedProps.group_name );
                        $('.modal-body div.group').show();
                    }

                    $('.modal-body div.location').hide();
                    if(info.event.extendedProps.location_id > 0) {
                        $('.modal-body span.location').text( info.event.extendedProps.location )
                        $('.modal-body div.location').show();
                    }
                });

                myModal.show();
            },
            windowResize: function(arg) {
                //For smaller screens set view to 'listWeek'
                if(window.innerWidth > 980) {
                    calendar.changeView('timeGridWeek');
                    calendar.setOption('headerToolbar', { right: 'timeGridWeek,listWeek' });
                } else {
                    calendar.changeView('listWeek');
                    calendar.setOption('headerToolbar', { right: '' });
                }
            }
        });
        calendar.render();

        if(window.innerWidth <= 980) {
            calendar.changeView('listWeek');
            calendar.setOption('headerToolbar', { right: '' });
        }
    }

    function doSearchAndRefine(url) {
        let redirect = window.location.origin + window.location.pathname;

        url = url.replace(redirect, "");
        let params = url.substring(1).split('&');

        $.each(params, function(key, val) {
           if(val.indexOf("search=") !== -1) {
               let search_val = $('.search input[name="search"]').val();
               if(search_val !== "") {
                   redirect = redirect + (key == 0 ? '?' : '&') + "search=" + search_val;
               }
           } else {
               redirect = redirect + (key == 0 ? '?' : '&') + val;
           }
        });

        window.location.href = redirect;
    }

    $('.refine select').each(function () {
        $(this).removeAttr("onchange");
    });

    function jQueryUI_datepicker() {
        if (typeof $.fn.datepicker === 'function') {
            $.datepicker.setDefaults( $.datepicker.regional[ "nl" ] );

            let uidp = $('.datepicker');
            uidp.each(function() {
                let $me = $(this);
                let minDate;
                let maxDate;
                let defaultDate;
                let disabledDays;
                let closedDays;
                let input;

                if ($me.data('disabled_dates')) {
                    disabledDays = $me.data('disabled_dates').split(',');
                }
                if($me.data('closed_dates')) {
                    closedDays = $me.data('closed_dates').split(',');
                }
                if($me.data('min')) {
                    minDate = $me.data('min');
                }
                if($me.data('max')) {
                    minDate = $me.data('max');
                }

                if($me.data('input')) {
                    input = $("input[name='" + $(this).data('input') + "']");
                    if(input.data('min')) {
                        minDate = input.data('min');
                    }
                    if(input.data('max')) {
                        maxDate = input.data('max');
                    }
                    defaultDate = input.val();
                }

                $me.datepicker({
                    dateFormat: "dd-mm-yy",
                    minDate: (minDate != null) ? minDate : null,
                    maxDate: (maxDate != null) ? maxDate : null,
                    defaultDate: (defaultDate != null) ? defaultDate : null,
                    nextText: '>',
                    prevText: '<',
                    showOtherMonths: true,
                    selectOtherMonths: true,
                    beforeShowDay: function(date) {
                        let string = jQuery.datepicker.formatDate('yy-mm-dd', date);

                        if(typeof ($me.data('disabled_dates')) !== "undefined") {
                            if(disabledDays != null && disabledDays.indexOf(string) === -1) {
                                return [ true, 'open', string ];
                            } else if(closedDays != null && closedDays.indexOf(string) === -1) {
                                return [ false, 'closed', string ];
                            } else {
                                return [ false, 'closed']; // disabled
                            }
                        } else {
                            return [true, '', string];
                        }
                    },
                    onSelect: function(date) {
                        let params = {
                            date: date
                        };

                        if($me.data('href')) {
                            $.post($me.data('href'), params, function(response) {
                                $($me.data('rc')).html( response );
                            });
                        }

                        if($me.data('input')) {
                            input = $("input[name='" + $(this).data('input') + "']");

                            if( input.val() !== date ) {
                                input.attr('value', date ).trigger('change');
                            }
                        }
                    }
                });
            });
        }
    }

    $('.input-daterange').datepicker({
        format: 'dd-mm-yyyy',
        autoclose: true,
        calendarWeeks : true,
        clearBtn: true,
        disableTouchKeyboard: true
    });


    $body.on('change click', function(e) {
        let $target = $(e.target);

        let $refine_p_id = $('.refine select[name="p_id"]');
        let $refine_customer_type = $('.refine select[name="customer_type"]');
        let $refine_sec = $('.refine select[name="sec"]');
        let $refine_ctype = $('.refine select[name="ctype"]');
        let $refine_month = $('.refine select[name="month"]');
        let $refine_plaats = $('.refine select[name="plaats"]');
        let $refine_agid = $('.refine select[name="agid"]');
        let $apply_date_today = $('.apply_date_today');
        let $shorten = $('.shorten .read_more');
        let $full = $('.full .read_less');

        if (e.type === "click") {
            if ($target.is($apply_date_today)) {
                let now = moment();

                $( "#apply_date" ).datepicker( "setDate", now._d );
                return false;
            }

            if ($target.is($shorten)) {
                let pi = $target.closest( ".shorten" ).data("pi");
                $target.closest( ".shorten" ).hide();
                $(".full[data-pi='" + pi + "']").show();
            }
            if ($target.is($full)) {
                let pi = $target.closest( ".full" ).data("pi");
                $target.closest( ".full" ).hide();
                $(".shorten[data-pi='" + pi + "']").show();
            }

        } else if (e.type === "change") {
            if ($target.is($refine_p_id)) {
                doSearchAndRefine( $refine_p_id.val() );
            }
            if ($target.is($refine_customer_type)) {
                doSearchAndRefine( $refine_customer_type.val() );
            }
            if ($target.is($refine_sec)) {
                doSearchAndRefine( $refine_sec.val() );
            }
            if ($target.is($refine_ctype)) {
                doSearchAndRefine( $refine_ctype.val() );
            }
            if ($target.is($refine_month)) {
                doSearchAndRefine( $refine_month.val() );
            }
            if ($target.is($refine_plaats)) {
                doSearchAndRefine( $refine_plaats.val() );
            }
            if ($target.is($refine_agid)) {
                doSearchAndRefine( $refine_agid.val() );
            }
        }
    });

    if($('.shorten').length > 0) {
        $('.shorten').show();
    }
});
